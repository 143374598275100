import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
`;

const Section = styled.section`
  margin: 20px auto 60px auto;
  padding: 0 20px;
  max-width: 680px;
`;

const NotFoundPage = () => (
  <Body>
    <SEO title="404: Not found" />
    <Layout>
      <Section>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <p>
          <Link to="/">To Home</Link>
        </p>
      </Section>
    </Layout>
  </Body>
);

export default NotFoundPage;
